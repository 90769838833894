import { closest, closestNode } from './domClosest'
import { hankaku } from './Zenkaku'

function getGroup(node) {
	return closest('.ajaxzip3-group', event.target) || closest('form', event.target)
}

export function initAjaxzip3OnInput() {
	window.addEventListener('input', event => {
		const group = getGroup(event.target)
		if (!group) return
		expand(group, event.target)
	})
}

export function initAjaxzip3OnClick() {
	window.addEventListener('click', event => {
		const btn = closest('.ajaxzip3-click', event.target)
		if (!btn) return
		const group = getGroup(btn)
		if (!group) return
		expand(group)
	})
}

function cleanName(name) {
	return name
	.replace(/^\w+\[([\w-]+)\]$/, '$1')
	.replace(/[_-]/g, ' ') // 区切り文字をスペースに置換
}

function expand(group, input) {
	if (!window.AjaxZip3 || !AjaxZip3.zip2addr) return

	let zip1, zip2, pref, city, addr

	// 各フォーム要素を特定する
	;[].forEach.call(group.querySelectorAll('input, select'), input => {
		const rawName = input.getAttribute('name')
		if (rawName == null) return

		// mkform では名称で区別できないので class で判別する
		const name = cleanName(rawName + ' ' + input.className)

		if (/\b(?:zip|post ?code|postal ?code)2\b/.test(name)) {
			zip2 = input
		} else if (/\b(?:zip|post ?code|postal ?code)1?\b/.test(name)) {
			zip1 = input
		} else if (/\b(?:pref|prefecture)\b/.test(name)) {
			pref = input
		} else if (/\b(?:city)\b/.test(name)) {
			city = input
		} else if (/\b(?:addr|address)1?\b/.test(name)) {
			addr = input
		}
	})
	if (!zip1) return

	// input イベントで起動する場合は、イベントの発生元が郵便番号でないといけない
	if (input && ((zip2 && zip2 !== input) || (!zip2 && zip1 !== input))) return

	// 郵便番号を正規化する
	if (zip2) {
		zip1.value = hankaku(zip1.value)
		zip2.value = hankaku(zip2.value)
	} else {
		zip1.value = hankaku(zip1.value).replace(/^([0-9]{3})([0-9]{4})$/, '$1-$2')
	}

	// 引数を組み立てる
	const args = [zip1, zip2]
	if (!pref) {
		args.push(addr)
	} else {
		args.push(pref)
		if (city) {
			args.push(city)
		}
		if (addr) {
			args.push(addr)
		}
	}
	
	AjaxZip3.zip2addr.apply(AjaxZip3, args)
}
