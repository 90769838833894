// ページ内リンクをなめらかにスクロール
import Scroll from '../zzl-lib/Scroll'
import { closest } from '../zzl-lib/domClosest'

const UA = navigator.userAgent || ''
const iOS = /\b(?:iPhone|iPad|iPod)\b/.test(UA)
const IE11 = /\bTrident\b/.test(UA)
const Edge = /\bEdge\b/.test(UA)
const Chrome = /\bChrome\b/.test(UA)
const Safari = !Edge && !Chrome && /\bSafari\b/.test(UA)

const scrollManager = (() => {
	if (!document.body.scrollIntoView || iOS || IE11 || Edge || Safari) return new Scroll()
	return null
})()

document.addEventListener('click', function (event) {
	const target = closest('a', event.target)
	if (!target || !target.hash) return
	if (target.href.replace(/#.*/, '') !== document.URL.replace(/#.*/, '')) return

	event.preventDefault()
	const targetE = document.getElementById(target.hash.replace(/^#/, ''))
	if (!targetE) return

	if (scrollManager) {
		scrollManager.toElement(targetE)
	} else {
		targetE.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		})
	}
})
