/* global zzl */
import DOM from '../zzl-lib/dom/dom'

const h = DOM.create
const listEs = document.querySelectorAll('.home-slide-list')
let glider = null

if (listEs.length > 0) {
	window.addEventListener('load', () => {
		if (!window.zzl || !zzl.env || !zzl.env.uploadImagePrefix) {
			console.warn('home-slide.js: Missing uploadImagePrefix config')
			return
		}

		[].forEach.call(listEs, (listE) => {
			const bridge = new BridgeSlide(listE)
			if (bridge.enabled) {
				setAutoplay(bridge.glider, 5000)
			}
		})
	})
}

class BridgeSlide {
	constructor (listE) {
		this.enabled = false

		const imagesJson = listE.getAttribute('data-images')
		if (!imagesJson) {
			console.warn('home-slide.js: Missing data-images attribtue')
			return
		}
		const images = JSON.parse(imagesJson)

		const prefix = zzl.env.uploadImagePrefix
		const itemsE = DOM.fragment(
			images.map(image => {
				const picture = h('picture', {}, [
					h('source', { media: '(max-width: 750px)', srcset: prefix.concat(image.src) }),
					h('source', { media: '(min-width: 751px)', srcset: prefix.concat(image.src) }),
					h('img', { alt: image.title, src: prefix.concat(image.src) })
				])
				const item = image.link_url ?
					h('a.home-slide-item', { href: image.link_url, target: image.link_target }, [picture]) :
					h('div.home-slide-item', {}, [picture])
				return item
			})
		)
		listE.innerHTML = ''
		listE.appendChild(itemsE)

		const frameE = listE.parentNode
		const containerE = frameE.parentNode
		;[].forEach.call(containerE.querySelectorAll('.hidden-noscript'), E => E.classList.remove('hidden-noscript'))

		this.listE = listE
		this.glider = new Glider(listE, {
			slidesToShow: 1,
			slidesToScroll: 1,
			draggable: true,
			rewind: true,
			dots: containerE.querySelector('.dots'),
			arrows: {
				prev: containerE.querySelector('.btn-slide-prev'),
				next: containerE.querySelector('.btn-slide-next')
			}
		})

		this.enabled = true
	}
}

function setAutoplay (glider, interval) {
	const element = glider.ele

	let autoplay = setInterval(() => {
		glider.scrollItem('next')
	}, interval)

	element.addEventListener('mouseover', (event) => {
		if (autoplay != null) {
			clearInterval(autoplay)
			autoplay = null
		}
	})

	element.addEventListener('mouseout', (event) => {
		if (autoplay == null) {
			autoplay = setInterval(() => {
				glider.scrollItem('next')
			}, interval)
		}
	})
}
